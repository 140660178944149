import { Box, Divider, Popover, Typography, Button, Grid, Stack, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HttpRequest } from '../HttpRequest';
import { ArrowForward, ArrowForwardIos, BoyRounded, ImageRounded, } from '@mui/icons-material';
import secureLocalStorage from 'react-secure-storage';
import { useSelector } from 'react-redux';

const Navbar = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tokenGenerated, setTokenGenerated] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState([]);
    const [parentId, setParentId] = useState('');
    const [subCategoryList, setSubCategoryList] = useState([]);

    const {businessPhone} = useSelector((state) => state.configs);

    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';

    const getCategories = () => {
        setLoading(true);
        let businessNumber = secureLocalStorage.getItem("businessId");
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_categories?businessNumber=${businessNumber}&limit=1000`
        }).then(async (data) => {
            const categories = data.response.data;
            setCategories(categories);
        }).catch((error) => {
        }).finally(() => {
            setLoading(false);
        });
    };
    const getBusinessToken = async () => {
        const businessNumber = secureLocalStorage.getItem('businessId');
        if (!businessNumber) {
            const currentDomain = window.location.origin;
            HttpRequest({
                method: 'GET',
                url: `/api/v1/ecommerce/getzedecommerce-domain-details?subdomain=${currentDomain}`,
            }).then(async (data) => {
                if (data.status === 200) {
                    secureLocalStorage.setItem('businessId', data.response.businessNumber);
                    setTokenGenerated(true);
                }
            }).catch((error) => {

            });
        } else {
            setTokenGenerated(true);
        }
    };
    const filterSubcategoriesByParentCategoryId = (parentId) => {
        return categories.find(category => {
            if (category.subCategories && category.subCategories.length > 0) {
                return category.subCategories.some(subCategory => subCategory.parentCategoryId === parentId?._id || subCategory.parentCategoryName === parentId?.parentCategoryName);
            }
            return false;
        });
    };
    useEffect(() => {
        if (!tokenGenerated) {
            getBusinessToken();
        } else {
            getCategories();
        }
    }, [tokenGenerated, open]);
    useEffect(() => {
        setCategoryList(categories);

    }, [open]);
    useEffect(() => {
        if (parentId) {
            setSubCategoryList(filterSubcategoriesByParentCategoryId(parentId)?.subCategories);
        } else {
            setSubCategoryList([]);
        }
    }, [parentId]);
    return (
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
            <Box className="navbar" >
                <Box>
                    <Box onClick={() => setOpen(true)} sx={{ height: '40px', margin: '0px 20px 0px 0px', padding: '20px 20px 0px 20px', borderRadius: '4px', border: 'solid 1px #e0f4f3', color: open ? '#fff' : primaryColor, backgroundColor: open ? primaryColor : '#fff', cursor: 'pointer' }} variant="contained" >
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ margin: '0 13px 0 0', objectFit: 'contain' }}>
                                <ListIcon />
                            </Box>
                            <Box sx={{ margin: '2px 0 2px 13px', fontSize: '14px', textAlign: 'left' }}>Browser All Categories</Box>
                            <Box sx={{ margin: '0 0 4px 30px', objectFit: 'contain' }}>
                                <ExpandMoreIcon />
                            </Box>
                        </Box>
                    </Box>
                    <Stack sx={{ justifyContent: 'start', textAlign: 'left', position: 'absolute', zIndex: 20, minWidth: '290px', maxWidth: '900px', margin: '0px 0 0', padding: '13px 74px 17px 0', minHeight: '330px', display: categoryList?.length > 0 && open ? 'block' : 'none' }} onMouseLeave={() => { setOpen(false); }}>
                        <Paper sx={{ display: 'flex', }}>
                            <Box sx={{ width: '100%', height: 'auto', minHeight: '300px', margin: '0 0 0 0.5px', minWidth: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                                {categoryList?.map((category) => (
                                <Box variant="contained" sx={{ textAlign: 'left', justifyContent: 'center', cursor: !filterSubcategoriesByParentCategoryId(category) ? 'pointer' : 'default' }} onMouseEnter={() => { setParentId(category) }} onClick={() => { navigate(`/products?categoryName=${category.categoryName}`); setOpen(false) }} >
                                        <Box sx={{ display: 'flex', ml: '5%', mr: '5%', justifyContent: 'space-between', alignItems: 'center', textAlign: 'left' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {category?.thumbnail ? <img src={category?.thumbnail} style={{ width: '40px', height: '40px', margin: '0 17px 0 0', objectFit: 'contain', color: primaryColor }} /> : <ImageRounded sx={{ color: primaryColor, width: '40px', height: '40px', margin: '0 17px 0 0', objectFit: 'contain', color: primaryColor }} />}
                                                <Typography sx={{ textWrap: 'wrap', margin: '1px 13px 1px 0px', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: '#000', justifyContent: 'start' }}>{category.categoryName}</Typography>
                                            </Box>
                                            {filterSubcategoriesByParentCategoryId(category) && <ArrowForwardIos sx={{ width: '20px', height: '20px', margin: '0 10px 0 0px', objectFit: 'contain', color: '#9C9B9C' }} />}
                                        </Box>
                                        <Divider sx={{ width: '100%', height: '1px', margin: '9px 0 11px', backgroundColor: '#e0f6f5', maxWidth: '320px' }} />
                                    </Box>
                                ))}
                            </Box>
                            <Grid container spacing={0} sx={{ backgroundColor: '#fff', width: '100%', minWidth: '558px', height: '100%', minHeight: `${subCategoryList?.length * 40}px`, margin: '7px 0 30px 30px', display: subCategoryList?.length > 0 && open === true ? 'flex' : 'none', flexWrap: 'wrap' }}>
                                {subCategoryList?.map((subcategory, index) => (
                                    <Grid item xs={6} style={{ display: 'flex', flexWrap: 'wrap', cursor: 'pointer', borderRight: 'solid 1px #e0f6f5', maxHeight: '25px', height: '100%' }} onClick={() => { navigate(`/products?categoryName=${subcategory.categoryName}`); setOpen(false) }}>
                                        <Box sx={{ margin: '0px 58px 0px 20px', display: 'flex', cursor: 'pointer', alignItems: 'center', textAlign: 'left' }}>
                                            {subcategory?.thumbnail ? <img src={subcategory?.thumbnail} style={{ width: '40px', height: '40px', margin: '0 17px 0 0', objectFit: 'contain', color: primaryColor }} /> : <ImageRounded sx={{ color: primaryColor, width: '40px', height: '40px', margin: '0 17px 0 0', objectFit: 'contain', color: primaryColor }} />}
                                            <Typography sx={{ textWrap: 'wrap', margin: '1px 0 0 5px', fontSize: '16px', textAlign: 'left', color: '#707070' }}>{subcategory?.categoryName}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Stack>
                </Box>
                <Box className="navbar_links">
                    <Link to="/" className={"navbar-link active"} style={{ color: window.location.pathname === '/' ? primaryColor : bodyColor,fontWeight: window.location.pathname === '/' ? 'bold' : 'normal', textDecoration: "none" }}>Home</Link>
                    <Link to="/products" className="navbar-link" style={{ color: window.location.pathname === '/products' ? primaryColor : bodyColor, textDecoration: "none",fontWeight: window.location.pathname === '/products' ? 'bold' : 'normal' }}>Products</Link>
                    <Link to="/contacts" className="navbar-link" style={{ color: window.location.pathname === '/contacts' ? primaryColor : bodyColor, textDecoration: "none",fontWeight: window.location.pathname === '/contacts' ? 'bold' : 'normal' }}>Contacts</Link>
                    <Link to="/blogs" className="navbar-link" style={{ color: window.location.pathname === '/blogs' ? primaryColor : bodyColor, textDecoration: "none",fontWeight: window.location.pathname === '/blogs' ? 'bold' : 'normal' }}>Blogs</Link>
                    <Link to="/faqs" className="navbar-link" style={{ color: window.location.pathname === '/faqs' ? primaryColor : bodyColor, textDecoration: "none",fontWeight: window.location.pathname === '/faqs' ? 'bold' : 'normal' }}>FAQS</Link>
                    <Box className="navbar-link phone_bar">
                        <Box className="navbar-link__icon">
                            <LocalPhoneIcon style={{ color: primaryColor }} />
                        </Box>
                        <Box className="navbar-link__text">
                            {businessPhone}
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default Navbar