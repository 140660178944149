import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpRequest } from "../../../HttpRequest";
import { useDispatch } from "react-redux";
import { logInUser } from "../../../../features/userSlice";
import { setAdditionalServices, setBusinessName } from "../../../../features/configsSlice";
import secureLocalStorage from "react-secure-storage";
import { setBanners, setButtons, setColors, setDisclaimer, setLogo, setPreOrderBadgeType, setTemplate, setTypography } from "../../../../features/template";

const zedUrl = process.env.REACT_APP_ZED_URL;

const AdminLogin = () => {
    const [fetchStatus, setFetchStatus] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchAdditionalServicesConfigs = () => {
        HttpRequest({
            method: 'GET',
            url: `/api/v1/ecommerce/get_additional_services_status`,
        }).then((data) => {
            if (data.status === 200) {
                const payload = data.response.data;
                dispatch(setAdditionalServices(payload));
            } 
        }).catch((error) => {
        });
    };

    const getBusinessInfo = () => {
        HttpRequest({
          method: 'POST',
          url: `/api/get_business_info`,
        }).then((data) => {
          if (data?.status === 200) {
            dispatch(setBusinessName(data?.response?.data?.businessName));

            const templateData = data?.response?.data?.ecommerceTemplateConfig;
            dispatch(setTemplate(templateData?.template));
            dispatch(setLogo(templateData?.logo));
            dispatch(setColors(templateData?.colors));
            dispatch(setTypography(templateData?.typography));
            dispatch(setButtons(templateData?.buttons));
            dispatch(setBanners(templateData?.banner));
            dispatch(setPreOrderBadgeType(templateData?.preOrderBadgeType));
            dispatch(setDisclaimer(templateData?.disclaimer));
          }
        }).catch((error) => {
        });
    };

    useEffect(() => {
        const loginParams = new URLSearchParams(location.search);

        const businessId = loginParams.get('businessId');
        const businessNumber = loginParams.get('businessNumber');
        const businessCategory = loginParams.get('businessCat');
        const userId = loginParams.get('userId');

        if (businessId !== null && businessNumber !== null && businessCategory === 'ZED_ECOMMERCE' && userId !== null) {
            let loginPayload = {
                "businessNumber": businessNumber,
                "businessId": businessId,
                "userId": userId,
                "branchId": ""
            };
            HttpRequest({
                method: 'POST',
                url: `/api/v1/ecommerce/zed_ecommerce_login`,
                body: loginPayload
            }).then((data) => {
                if (data.status === 200) {
                    secureLocalStorage.setItem("group", "admin");
                    secureLocalStorage.setItem("token", data.response.data.token);
                    secureLocalStorage.setItem("businessId", data.response.data.businessNumber);
                    setFetchStatus(true);
                    dispatch(logInUser({ isLoggedIn: true }));
                    fetchAdditionalServicesConfigs();
                    getBusinessInfo();
                    navigate('/zed_ecommerce/dashboard');
                } else {
                    window.location.href = zedUrl;
                }
            }).catch((error) => {
                window.location.href = zedUrl;
            });
        } else {
            window.location.href = zedUrl;
        }
    }, [location.search]);

    return null;
};

export default AdminLogin;
