import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import BannerOne from "../../images/banner1.png";
// import BannerTwo from "../../images/banner2.png";
// import BannerThree from "../../images/banner3.png";
// import BannerFour from "../../images/banner4.png";
// import BannerFive from "../../images/banner5.png";
// import BannerSix from "../../images/banner6.png";
import BannerOne from "../../images/banners/Eastwest-Banners-1.png";
import BannerTwo from "../../images/banners/Eastwest-Banners-2.png";
import BannerThree from "../../images/banners/Eastwest-Banners-3.png";
import BannerFour from "../../images/banners/Eastwest-Banners-4.png";
import BannerFive from "../../images/banners/Eastwest-Banners-5.png";
import BannerSix from "../../images/banners/Eastwest-Banners-6.png";
import BannerSeven from "../../images/banners/Eastwest-Banners-7.png";
import BannerEight from "../../images/banners/Eastwest-Banners-8.png";
import BannerNine from "../../images/banners/Eastwest-Banners-9.png";
import BannerTen from "../../images/banners/Eastwest-Banners-10.png";
import BannerEleven from "../../images/banners/Eastwest-Banners-11.png";

import "./index.css";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BannerPage = () => {
    const [slidesToShow, setSlidesToShow] = useState(1);
    const { template, logo, colors, typography, buttons, banners, preOrderBadgeType, disclaimer } = useSelector((state) => state.template);
    console.log('Loading banner page',banners);
    const navigate = useNavigate();
    const headingsColor = 'var(--headings)';
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 600) {
                setSlidesToShow(1);
            } else if (window.innerWidth <= 960) {
                setSlidesToShow(2);
            } else {
                setSlidesToShow(3);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: banners?.numberOfBanners === 1 ? 1 : 3,
        slidesToScroll: banners?.numberOfBanners === 1 ? 1 : 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const sliderStyle = {
        margin: '-40px 0px 0 0px',
    };

    return (
        <Box style={{ border: 'none' }}>
            <Typography variant="h1" component="h2" sx={{ color: headingsColor, fontSize: "14px", fontWeight: "bold", paddingLeft: '10px' }}> Great Offers!!</Typography>
            <Box className="banner" style={{ border: 'none' }}>
                <Box className="banner__content" >
                    {banners?.numberOfBanners > 0 ?
                        <Slider {...settings} style={sliderStyle}>
                            {banners?.path?.map((path) => (
                                <div style={{ border: 'none', cursor: 'pointer' }} onClick={() => navigate('/products')}>
                                    <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px'}} src={path?.url} alt="Image 1" />
                                </div>
                            ))}

                        </Slider>
                        :
                        <Slider {...settings} style={sliderStyle}>
                            <div style={{ border: 'none', cursor: 'pointer' }} onClick={() => navigate('/products')}>
                                <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }} src={BannerOne} alt="Image 1" />
                            </div>
                            <div style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }}>
                                <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }} src={BannerTwo} alt="Image 2" onClick={() => navigate('/products')} />
                            </div>
                            <div style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }}>
                                <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }} src={BannerThree} alt="Image 3" onClick={() => navigate('/products')} />
                            </div>
                            <div style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }}>
                                <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }} src={BannerFour} alt="Image 4" onClick={() => navigate('/products')} />
                            </div>
                            <div style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }}>
                                <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }} src={BannerFive} alt="Image 5" onClick={() => navigate('/products')} />
                            </div>
                            <div style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }}>
                                <img style={{ border: 'none', cursor: 'pointer',maxWidth:'350px' }} src={BannerSix} alt="Image 6" onClick={() => navigate('/products')} />
                            </div>
                        </Slider>
                    }

                </Box>
            </Box>
        </Box>
    );
}

export default BannerPage;
