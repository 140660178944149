import { Box, Grid } from '@mui/material'
import React from 'react'
import "./index.css"
import TopNavBar from './topNavBar';
import SearchNavBar from './searchBar';
import Navbar from './navbar';
import BannerPage from './banner';
import Products from './products';
import Footer from './footer';

const HomePage = () => {

    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <Box sx={{ width: '100%', maxWidth: { xs: '350px', sm: '100%' } }}>
                    <BannerPage />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%', maxWidth: { xs: '320px',sm:'700px', md: '100%' } }}>
                    <Products />
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomePage