import { Box, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LogoutIcon from '@mui/icons-material/Logout';
import { ProfileDetails } from "./profileDetails";
import { HttpRequest } from "../../HttpRequest";
import { CustomerAdressDetails } from "./addressDetails";
import { MyCustomerOrders } from "./ordersCustomer";
import { logoutUser } from "../../../features/userSlice";
import { useDispatch } from "react-redux";
import { AccountCircle, EditRounded, ShoppingCartCheckoutOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const CustomerProfileSidebar = () => {
    const [activeTab, setActiveTab] = useState('orders');
    const [customerDetails, setCustomerDetails] = useState();
    const customerId = secureLocalStorage.getItem('customerId');
    const zedUrl = process.env.REACT_APP_ZED_URL;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const primaryColor = 'var(--primary)';
    const bodyColor = 'var(--body)';
    const headingsColor = 'var(--headings)';

    function getCustomerDetails() {
        try {
            HttpRequest({
                url: `/api/get_customer_by_customer_id?customerId=${customerId}`,
                method: 'GET',
            }).then((response) => {
                if (response?.status === 200) {
                    setCustomerDetails(response?.response?.data);
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        getCustomerDetails();
    }, [customerId]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    const tabs = [
        {
            label: 'My Orders',
            value: 'orders',
            icon: ShoppingBasketIcon,
        },
        {
            label: 'Personal Data',
            value: 'personalData',
            icon: AccountCircleIcon,
        },
        {
            label: 'Delivery Addresses',
            value: 'addresses',
            icon: PlaceIcon,
        },
    ]

    const handleLogout = () => {
        secureLocalStorage.clear();
        dispatch(logoutUser());
    }

    return (
        <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={12} xs={12} >
                <Box sx={{ width: '100%', maxWidth: '334px', padding: '40.9px 15px 27px 16px', height: '100%', maxHeight: '582px', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff', margin: '20px 16px 96px 3px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: "column", alignItems: 'center', width: '100%', maxWidth: '222px', height: '100%', maxHeight: '150.1px', margin: '0 81px 0px 25px' }}>
                        <AccountCircle sx={{ width: '126.7px', height: '131px', margin: '0 95.3px 22px 0', objectFit: 'contain', fontSize: '10px', color: bodyColor, borderTop: 'none', borderLeft: `5px solid ${primaryColor}`, borderBottom: 'none', borderRight: 'none', borderRadius: '50%' }} />
                        <Box sx={{ backgroundColor: primaryColor, borderRadius: '50%', justifyContent: 'center', alignItems: 'center', m: '-50px 0px 0px -10px' }}>
                            <EditRounded sx={{ width: '20px', height: '20px', color: '#fff', m: '4px 4px 0px 4px' }} />
                        </Box>
                    </Box>
                    <Box sx={{ margin: '22px 0 0 0px' }}>
                        <Typography sx={{ margin: '0 92px 1px 0', fontSize: '16px', fontWeight: 500, textAlign: 'left', color: headingsColor, textWrap: 'nowrap' }}>{customerDetails?.firstName} {customerDetails?.lastName}</Typography>
                        <Typography sx={{ margin: '1px 0 0', fontSize: '16px', fontWeight: 'normal', textAlign: 'left', color: bodyColor }}>{customerDetails?.email}</Typography>
                    </Box>
                    <Box>
                        {tabs.map((tab, index) => (
                            <Box key={index} onClick={() => handleTabChange(tab.value)} sx={{ padding: '15px 0px 15px 15px', alignItems: 'center', display: "flex", margin: '12px 0 10px', cursor: 'pointer', borderRadius: '10px', backgroundColor: activeTab === tab.value ? '#effffe' : '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', width: '100%', maxWidth: "303px", height: '100%', maxHeight: '48px' }}>
                                {tab.icon && <tab.icon sx={{ marginRight: '10px', color: activeTab === tab.value ? primaryColor : bodyColor }} />}
                                <Typography sx={{ color: activeTab === tab.value ? primaryColor : bodyColor, margin: '2px 0 2px 24px', fontSize: '14px', fontWeight: activeTab === tab.value ? 600 : 'normal', textAlign: 'left' }}>{tab.label}</Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box component={'div'} onClick={() => navigate('/products')} sx={{ padding: '3px 0px 3px 15px', alignItems: 'center', display: "flex", margin: '12px 0 10px', cursor: 'pointer', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', width: '100%', maxWidth: "303px", height: '100%', maxHeight: '48px' }}>
                        <ShoppingCartCheckoutOutlined sx={{ color: bodyColor, marginRight: '10px', }} />
                        <Typography sx={{ color: bodyColor, margin: '2px 0 2px 24px', fontSize: '14px', fontWeight: 'normal', textAlign: 'left' }}>Continue Shopping</Typography>
                    </Box>
                    <Box component={'div'} onClick={handleLogout} sx={{ padding: '3px 0px 3px 15px', alignItems: 'center', display: "flex", margin: '12px 0 10px', cursor: 'pointer', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', width: '100%', maxWidth: "303px", height: '100%', maxHeight: '48px' }}>
                        <LogoutIcon sx={{ color: '#e20613', marginRight: '10px', }} />
                        <Typography variant="p" sx={{ color: '#e20613', margin: '2px 0 2px 24px', fontSize: '14px', fontWeight: 'normal', textAlign: 'left' }}>Logout</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item lg={8} md={7} sm={12} xs={12}>
                <Box component={Paper} display={'flex'} sx={{ width: '100%', maxWidth: { xs: '340px', sm: '684px' }, height: '100%', maxHeight: '582px', margin: { xs: '0px', sm: '20px 23px 20px 16px' }, padding: '12px 17.9px 12px 10px', borderRadius: '15px', boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.05)', backgroundColor: '#fff' }}>
                    {activeTab === 'orders' && <MyCustomerOrders customerDetails={customerDetails} />}
                    {activeTab === 'personalData' && <ProfileDetails customerDetails={customerDetails} />}
                    {activeTab === 'addresses' && <CustomerAdressDetails customerDetails={customerDetails} />}
                </Box>
            </Grid>
        </Grid>
    )
}
export { CustomerProfileSidebar }

